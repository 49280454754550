import * as React from 'react';
import {Book} from './library';
import * as Yup from 'yup';
import {Formik, Field, FormikHelpers} from 'formik';
import FieldGroup from './field-group';
import {FormGroup, Label, Input, Button, Form} from 'reactstrap';
import {firebaseApp} from './firebase';
import {toast} from 'react-toastify';

type MaybeExistingBook = Omit<Book, 'id'> & {id?: string};
interface Props {
  initialBook?: MaybeExistingBook;
}

type BookFields = Pick<
  Book,
  'author' | 'format' | 'title' | 'finished' | 'library'
>;

const BookValidationSchema = Yup.object().shape({
  author: Yup.string().required(),
  format: Yup.string()
    .oneOf(['audiobook', 'ebook'])
    .required(),
  title: Yup.string().required(),
  finished: Yup.boolean().required(),
  library: Yup.string(),
});

const newBook: MaybeExistingBook = {
  title: '',
  author: '',
  format: 'ebook',
  finished: false,
  library: '',
};

const BookForm: React.FC<Props> = (props) => {
  const {initialBook = newBook} = props;
  const handleSubmit = React.useCallback(
    async (values: typeof initialBook, helpers: FormikHelpers<any>) => {
      if (initialBook.id) {
        await firebaseApp
          .firestore()
          .collection('books')
          .doc(initialBook.id)
          .update(values);
      } else {
        helpers.resetForm();
        await firebaseApp
          .firestore()
          .collection('books')
          .doc(`${values.title}|||${values.author}|||${values.format}`)
          .set({
            ...values,
            createdAt: new Date().toISOString(),
          });
      }
      helpers.setSubmitting(false);
      toast('Book saved', {type: 'success'});
    },
    [initialBook],
  );
  return (
    <Formik
      initialValues={initialBook}
      onSubmit={handleSubmit}
      validationSchema={BookValidationSchema}
    >
      {(formikProps) => (
        <Form>
          <div className="fields">
            <Field component={FieldGroup} name="title" label="Title" />
            <Field component={FieldGroup} name="author" label="Author" />
            <FormGroup check className="mb-3">
              <Label check>
                <Input
                  type="checkbox"
                  checked={formikProps.values.finished}
                  onChange={formikProps.handleChange}
                  name="finished"
                  onBlur={formikProps.handleBlur}
                />{' '}
                Finished
              </Label>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="format">Format</Label>
              <Input
                type="select"
                value={formikProps.values.format}
                onChange={formikProps.handleChange}
                name="format"
                id="format"
                onBlur={formikProps.handleBlur}
              >
                <option value="ebook">ebook</option>
                <option value="audiobook">audiobook</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="library">Library</Label>
              <Input
                type="select"
                value={formikProps.values.library}
                onChange={formikProps.handleChange}
                name="library"
                onBlur={formikProps.handleBlur}
              >
                <option value=""></option>
                <option value="NYPL">NYPL</option>
                <option value="Tempe">Tempe</option>
                <option value="Maricopa County">Maricopa County</option>
                <option value="Knox County">Knox County</option>
                <option value="Montana">Montana</option>
              </Input>
            </FormGroup>
          </div>
          <Button
            onClick={formikProps.submitForm}
            disabled={formikProps.isSubmitting}
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default BookForm;
