import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCNeLQEsi4XUvHg9vla-HL1itaEx4HbrLU',
  authDomain: 'personal-library-e.firebaseapp.com',
  databaseURL: 'https://personal-library-e.firebaseio.com',
  projectId: 'personal-library-e',
  storageBucket: 'personal-library-e.appspot.com',
  messagingSenderId: '65025968067',
  appId: '1:65025968067:web:58a2d514a13ae66fb8df76',
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
