import * as React from 'react';
import {User} from 'firebase';
import {firebaseApp} from './firebase';

interface CurrentUserHook {
  user: User | null;
}

const useCurrentUser = (): CurrentUserHook => {
  const [user, setUser] = React.useState<User | null>(() => {
    const user = localStorage.getItem('user');
    if (user) {
      return JSON.parse(user);
    }
  });

  React.useEffect(() => {
    return firebaseApp.auth().onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        localStorage.setItem('user', JSON.stringify(user.toJSON()));
      } else {
        localStorage.setItem('user', JSON.stringify(null));
      }
    });
  }, []);
  return {user};
};

export default useCurrentUser;
