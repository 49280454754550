import React from 'react';
import './App.scss';
import LoginPage from './login-page';
import useCurrentUser from './use-current-user';
import Library from './library';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const App: React.FC = () => {
  const {user} = useCurrentUser();
  return (
    <div className="App">
      {user ? (
        <div>
          <Library />
        </div>
      ) : (
        <LoginPage />
      )}
    </div>
  );
};

export default App;
