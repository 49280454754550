import * as React from 'react';
import {FormGroup, Label, Input, FormFeedback, InputProps} from 'reactstrap';
import uuid from 'uuid';
import {FieldInputProps} from 'formik';
import isString from 'lodash/isString';
import get from 'lodash/get';
import isObject from 'lodash/isObject';

interface Props extends InputProps {
  label?: string;
  field: FieldInputProps<string>;
  form: any; // not sure what type this should be in a generic setting
}

const FieldGroup: React.FC<Props> = (props) => {
  const {label, field, form, ...inputProps} = props;
  const [id] = React.useState(() => `fg-${uuid.v4()}`);
  const touched: boolean = get(props.form.touched, props.field.name);
  const error = get(props.form.errors, props.field.name);
  const showError: boolean = !!get(props.form.errors, props.field.name);
  return (
    <FormGroup>
      <Label htmlFor={id}>{props.label || props.field.name}</Label>
      <Input
        id={id}
        valid={touched ? !showError : undefined}
        invalid={touched ? showError : undefined}
        {...props.field}
        {...inputProps}
      />
      <FormFeedback>
        {isString(error)
          ? error
          : isObject(error)
          ? Object.values(error).map((e, i) => (
              <span key={i}>{JSON.stringify(e)}</span>
            ))
          : 'Invalid'}
      </FormFeedback>
    </FormGroup>
  );
};

export default FieldGroup;
