import * as React from 'react';
import {firebaseApp} from './firebase';
import {useTable, Column} from 'react-table';
import {
  Table,
  Input,
  FormGroup,
  Label,
  Container,
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Fuse from 'fuse.js';
import {toast} from 'react-toastify';
import './library.scss';
import BookForm from './book-form';

export type Format = 'audiobook' | 'ebook';
export interface Book {
  author?: string;
  format: Format;
  id: string;
  title: string;
  library?: string;
  finished?: boolean;
  createdAt?: string;
}

interface Props {}

const Library: React.FC<Props> = (props) => {
  const [books, setBooks] = React.useState<Book[] | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<null | string>(null);

  const [bookFormBook, setBookFormBook] = React.useState<null | true | Book>(
    null,
  );
  const closeBookForm = React.useCallback(() => {
    setBookFormBook(null);
  }, [setBookFormBook]);

  const updateFinished = React.useCallback(
    async (id: string, finished: boolean) => {
      try {
        await firebaseApp
          .firestore()
          .doc(`books/${id}`)
          .update({finished});
      } catch (err) {
        console.error(err);
        toast(`Error updating book ${err.message}`);
      }
    },
    [],
  );

  const handleDelete = React.useCallback(async (book: Book) => {
    try {
      await firebaseApp
        .firestore()
        .doc(`books/${book.id}`)
        .delete();
    } catch (err) {
      console.error(err);
      toast(`Error deleting book ${err.message}`);
    }
  }, []);

  React.useEffect(() => {
    firebaseApp
      .firestore()
      .collection('books')
      .onSnapshot(
        (ss) => {
          setIsLoading(false);
          setError('');
          setBooks(ss.docs.map((d) => d.data() as Book));
        },
        (err) => {
          console.error(err);
          setError(err.message);
          toast(`Error fetching books ${err.message}`);
        },
      );
  }, []);

  const columns = React.useMemo<Column[]>(
    () => [
      {Header: 'Title', accessor: 'title'},
      {
        Header: 'Author',
        accessor: 'author',
        Cell: (props) => <div className="text-nowrap">{props.cell.value}</div>,
      },
      {Header: 'Format', accessor: 'format'},
      {
        Header: 'Finished',
        accessor: 'finished',
        Cell: (props) => (
          <div className="text-nowrap">
            <Button
              color="link"
              onClick={() =>
                updateFinished(
                  (props.row.original as Book).id,
                  !props.cell.value,
                )
              }
            >
              {props.cell.value === true ? 'Yes' : 'No'}
            </Button>
          </div>
        ),
      },
    ],
    [updateFinished],
  );

  const [searchString, setSearchString] = React.useState('');
  const [formatFilter, setFormatFilter] = React.useState<Format | ''>('');
  const [finishedFilter, setFinishedFilter] = React.useState<
    'true' | 'false' | ''
  >('false');

  const hardFilteredBooks = React.useMemo(() => {
    let filtered = books || [];
    if (formatFilter) {
      filtered = filtered.filter((b) => b.format === formatFilter);
    }
    if (finishedFilter !== '') {
      // debugger
      filtered = filtered.filter(
        (b) => (b.finished || false) === (finishedFilter === 'true'),
      );
    }
    return filtered;
  }, [books, formatFilter, finishedFilter]);

  const fuse = React.useMemo(() => {
    return new Fuse(hardFilteredBooks, {keys: ['title', 'author']});
  }, [hardFilteredBooks]);

  const filteredBooks = React.useMemo(() => {
    if (!searchString) {
      return hardFilteredBooks;
    }
    return fuse.search(searchString);
  }, [fuse, searchString, hardFilteredBooks]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: filteredBooks || [],
  });
  const changeSearchString = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchString(e.target.value);
    },
    [setSearchString],
  );
  const changeFinishedFilter = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFinishedFilter(e.target.value as 'true' | 'false' | '');
    },
    [setFinishedFilter],
  );
  const changeFormatFilter = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormatFilter(e.target.value as Format);
    },
    [setFormatFilter],
  );

  if (isLoading) {
    return <div>Loading books</div>;
  }
  if (error) {
    return <div>Error loading books {error}</div>;
  }
  if (!books) {
    return <div>No errors but no books</div>;
  }

  return (
    <Container className="library">
      <Button onClick={() => setBookFormBook(true)}>Add book</Button>
      <Form inline onSubmit={(e) => e.preventDefault()}>
        <FormGroup>
          <Label htmlFor="searchString">Search</Label>
          <Input
            id="searchString"
            value={searchString}
            onChange={changeSearchString}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="formatFilter">Format</Label>
          <Input
            id="formatFilter"
            value={formatFilter}
            onChange={changeFormatFilter}
            type="select"
          >
            <option value="audiobook">Audio</option>
            <option value="ebook">E-Book</option>
            <option value="">Any</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="finishedFilter">Finished</Label>
          <Input
            id="finishedFilter"
            value={finishedFilter}
            onChange={changeFinishedFilter}
            type="select"
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
            <option value="">Any</option>
          </Input>
        </FormGroup>
      </Form>
      <Table striped {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
              <th>Edit</th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
                <td>
                  <Button onClick={() => setBookFormBook(row.original as Book)}>
                    Edit
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Modal isOpen={bookFormBook !== null}>
        <ModalHeader toggle={closeBookForm}>Book</ModalHeader>
        <ModalBody>
          <BookForm
            initialBook={
              bookFormBook === true || bookFormBook === null
                ? undefined
                : bookFormBook
            }
          />
        </ModalBody>
        <ModalFooter>
          {bookFormBook !== true && bookFormBook !== null && (
            <Button
              color="danger"
              onClick={() => {
                handleDelete(bookFormBook);
                closeBookForm();
              }}
            >
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default Library;
